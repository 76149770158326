import React, { useState } from 'react'
import CateringItemCard from './CateringItemCard.jsx'

export default function CateringCategoryCard(props) {

const [showing, setShowing] = useState(false)

  const renderItems = () => {
    if (!showing) return
    return props.items.map((menuItem) => {
      return (
        <CateringItemCard
          key={menuItem.id}
          name={menuItem.name}
          halfPrice={menuItem.halfPrice}
          fullPrice={menuItem.fullPrice}>
        </CateringItemCard>
      )
    })
  }

  const toggleCategoryDisplay = () => {
    setShowing(!showing)
  }

  return (
    <div className='catering-category-card'>
      <span
        className='category-title'
        onClick={toggleCategoryDisplay}>
        {`- - - - ${props.category} - - - -`}
      </span>
      <section
        className='category-items'>
        {renderItems()}
      </section>
    </div>
  )
}
