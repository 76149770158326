import React from 'react'
import './App.scss'
import Header from './components/Header.jsx'
import Main from './components/Main.jsx'

export default function App() {

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    })
  })
  
  return (
    <div className="App">
      <Header></Header>
      <Main></Main>
    </div>
  )
}
