import React from 'react'
import { SocialIcon } from 'react-social-icons'

export default function Reviews() {

  const yelp = 'https://www.yelp.com/biz/calabria-pizza-oradell-2'
  const google = 'https://goo.gl/maps/Q9kVHV733HbSE7PNA'

  const handleYelp = (e) => {
    e.preventDefault()
    window.open(yelp, 'tab')
  }
  const handleGoogle = (e) => {
    e.preventDefault()
    window.open(google, 'tab')
  }

  return (
    <div className="reviews">
      <SocialIcon
        className="review-icon"
        url=""
        network="yelp"
        onClick={handleYelp} />
      <SocialIcon
        className="review-icon"
        url=""
        network="google"
        onClick={handleGoogle} />
    </div>
  )
}
