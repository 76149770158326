import React from 'react'

import svgLogo from '../svg-logo.svg'
import svgSlogan from '../svg-slogan.svg'

export default function Header() {

  return (
    <div className="header">
        <img
          src={svgLogo}
          className='logo'
          alt={`The restaurant's logo`}>
        </img>
        <img
          src={svgSlogan}
          className='slogan'
          alt={`The restaurant's slogan: A Taste of Italy in Your Neighborhood`}>
        </img>
        <section className='header-contact'>
          <a
            href={"tel:+1201-523-9228"}
            target='_self'
            className='header-link'>
            {'201-523-9228'}
          </a>
          <a
            href={"https://goo.gl/maps/QrgWdUeoPdYSnwLs8"}
            target='_blank'
            rel="noopener noreferrer"
            className='header-link'>
            <div>{'239 Kinderkamack Road'}</div>
            <div>{'Oradell, NJ 07649'}</div>
          </a>
        </section>
        <section className='header-hours'>
          <div>{`Monday - Saturday: 11am - 9pm`}</div>
          <div>{`Sunday: 4pm - 9pm`}</div>
        </section>
    </div>
  )

}
