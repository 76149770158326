import React from 'react'
import FoodCategoryCard from './FoodCategoryCard.jsx'
import ToTopButton from '../../components/ToTopButton.jsx'
let foodMenu = require('../../data/foodMenu.json')

export default function FoodMenu(props) {

  const handleTopClick = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const renderCategories = () => {
    const menuCategoryList = []
    for (let menuCategory in foodMenu) {
      menuCategoryList.push(
        <div key={menuCategory} className="food-category">
          <FoodCategoryCard
            category={menuCategory}
            subheading={foodMenu[menuCategory]["subheading"]}
            items={foodMenu[menuCategory]["items"]}>
          </FoodCategoryCard>
        </div>
      )
    }
    return menuCategoryList
  }

  return (
    <div className='food-menu'>
      <>{renderCategories()}</>
    <ToTopButton handleTopClick={handleTopClick}></ToTopButton>
    </div>
  )

}
