import React from 'react'

export default function ToTopButton(props) {
  return (
    <div
      className="btn-container"
      onClick={props.handleTopClick}>
      <span className="btn">{`⌃`}</span>
    </div>
  )
}
