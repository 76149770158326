import React from 'react'
import Announcements from './Announcements.jsx'
import Navigation from './Navigation.jsx'

export default function Main() {

  return(
    <div className="main">
      <Announcements></Announcements>
      <Navigation></Navigation>
    </div>
  )

}
