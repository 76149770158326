import React from 'react'

export default function AboutUs() {
  return (
    <div className="about-us">
      <p>
        {`Named for the region of southern Italy from which we originate, Calabria Pizza is a family run business dedicated to serving you the highest quality pizza and Italian cuisine Oradell has to offer. Family-friendly, a BYOB, outdoor seating, delivery and catering available.`}
      </p>
    </div>
  )
}
