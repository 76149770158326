import React from 'react'

export default class Newsletter extends React.Component {

  state = {
    emailMessage: '',
    message: '',
    status: null
  }

  handleChange = (event) => {
    this.setState({
      emailMessage: event.target.value,
      message: '',
      status: null
    })
  }

  postEmail = async (url='', payload={}) => {
    let { emailMessage } = this.state
// email is blank
    if (emailMessage === '') {
      this.setState({message: "An email is required."})
      } else {
// email is not blank => send request
      this.setState({message: "Adding to mailing list!"})
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          'email': payload
        })
      })
      return response
    }
  }

  sendPost = () => {
    let { emailMessage } = this.state
    this.postEmail('https://calabria-pizza-api.herokuapp.com/emails', {'email': emailMessage})
    .then(response => {
      // => 406 NOT ACCEPTABLE ("Please enter a valid email.")
      if (response && response.status === 406) {
        // console.log(response.status, "Please enter a valid email.");
        this.setState({
          message: "Please enter a valid email.",
          status: 406
        })
      // => 302 FOUND ("You're already on our mailing list!")
      } else if (response && response.status === 302) {
        // console.log(response.status, "You're already on our mailing list!");
        this.setState({
          message: "You're already on our mailing list!",
          status: 302
        })
      // => 202 ACCEPTED ("You've succesfuly re-subscribed!")
      } else if (response && response.status === 202) {
        // console.log(response.status, "You've succesfuly re-subscribed!");
        this.setState({
          message: "You've succesfuly re-subscribed!",
          status: 202
        })
      // => 201 CREATED ("You've succesfuly subscribed!")
      } else if (response && response.status === 201) {
        // console.log(response.status, "You've succesfuly subscribed!");
        this.setState({
          message: "You've succesfuly subscribed!",
          status: 201
        })
      }
      this.setState({emailMessage: ''})
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.sendPost()
  }

  handleKeyDown = (e) => {
    if (e.keyCode !== 13) return
    e.preventDefault()
    this.sendPost()
  }

renderMessage = () => this.state.message

render() {
    return (
      <div className="newsletter">
        <p>{`Stay updated with daily specials, seasonal items and special offers by registering for our mailing list!`}</p>
        <div className='newsletter-messages'>{this.renderMessage()}</div>
        <form className="email-form">
          <label className="email-label">
            {`Email `}
            <input
              className="email-input"
              type="text"
              name="email"
              value={this.state.emailMessage}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}>
            </input>
          </label>
          <input
            className="submit-button"
            type="button"
            value="Submit"
            onClick={this.handleSubmit}>
          </input>
        </form>
      </div>
    )
  }


}
