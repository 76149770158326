import React from 'react'
import { SocialIcon } from 'react-social-icons'

export default function Social() {

  const facebook = 'https://www.facebook.com/CalabriaPizzaOradell'
  const instagram = 'https://www.instagram.com/calabriapizza239'

  const handleFacebook = (e) => {
    e.preventDefault()
    window.open(facebook, 'tab')
  }
  const handleInstagram = (e) => {
    e.preventDefault()
    window.open(instagram, 'tab')
  }

  return (
    <div className="social">
      <SocialIcon
        className="social-icon"
        url=""
        network="facebook"
        onClick={handleFacebook} />
      <SocialIcon
        className="social-icon"
        url=""
        network="instagram"
        onClick={handleInstagram} />
    </div>
  )
}
