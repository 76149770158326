import React from 'react'
import outside1 from '../../pictures/outside1.png'
import outside2 from '../../pictures/outside2.png'
import outside3 from '../../pictures/outside3.png'
import outside4 from '../../pictures/outside4.png'
import outside5 from '../../pictures/outside5.png'

export default function Gallery() {
  return (
    <div className="gallery">
      <img
        src={outside1}
        alt="Outdoor seating area towards the street">
      </img>
      <img
        src={outside2}
        alt="Clients dining in the outdoor seating area">
      </img>
      <img
        src={outside3}
        alt="A pizza party held in the outdoor seating area">
      </img>
      <img
        src={outside4}
        alt="Calabria Pizza decorated for the holidays">
      </img>
      <img
        src={outside5}
        alt="The outdoor seating area of Calabria Pizza">
      </img>
    </div>
  )
}
