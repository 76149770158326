import React from 'react'

export default function FoodItemCard(props) {

  return (
    <section className="food-item-card">
      <p className="food-item-title">
        {`${props.name}`}
      </p>
      <p className="food-item-price">
        {`$${props.price}`}
      </p>
      <p className="food-item-desc">
        {`${props.desc}`}
      </p>
    </section>
  )

}
