import React from 'react'
import Detail from './Detail.jsx'
import { Link, withRouter } from 'react-router-dom'

class Item extends React.Component {

  renderDetails = () => {
    if (this.props.active || this.props.history.action.toString() === "POP") {
      return (
        <Detail
          name={this.props.name}
          getHistoryAction={this.getHistoryAction}>
        </Detail>
      )
    }
  }

  activeLinkStyle = () => {
    if (this.props.active) {
      return "link-to-active"
    } else {
      return "link-to"
    }
  }

  handleClick = () => {
    this.props.setActive(this.props.name)
  }

  determineTo = () => {
    return !this.props.active ? `/${this.props.name}` : ``
  }

  render() {
    return (
      <>
        <div className="item" onClick={this.handleClick}>
          <Link
            className={this.activeLinkStyle()}
            to={this.determineTo()}>
            {`[ ${this.props.name.toUpperCase()} ]`}
          </Link>
        </div>
          {this.renderDetails()}
      </>
    )
  }

}

export default withRouter(Item)
