import React from 'react'

export default function CateringItemCard(props) {

  return (
    <section className="food-item-card">
      <p className="food-item-title">
        {`${props.name}`}
      </p>
      <p className="food-item-price">
        {`Half Tray: $${props.halfPrice}`}
      </p>
      <p className="food-item-price">
        {`Full Tray: $${props.fullPrice}`}
      </p>
    </section>
  )

}
