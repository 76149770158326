import React from 'react'
import Item from './Item.jsx'

export default class Navigation extends React.Component {

  state = {
    activeItem: null,
    menuItems: [
      "menu",
      "catering",
      "order",
      "about",
      "social",
      "reviews",
      "gallery",
      "newsletter",
    ]
  }

  setActive = (itemName) => {
    if (this.state.activeItem === itemName) {
      this.setState({
        activeItem: null,
      })
    } else {
      this.setState({
        activeItem: itemName,
      })
    }
  }

  renderItems = () => {
    return this.state.menuItems.map((item, index) => {
      const isActive = item === this.state.activeItem
      return (
        <Item
          key={index}
          name={item}
          setActive={this.setActive}
          active={isActive}>
        </Item>
      )
    })
  }

  render() {
    return(
        <div className="navigation">
          {this.renderItems()}
        </div>
    )
  }

}
