import React from 'react'

export default function Announcements() {
  return(
    <div className="announcements">
      <p>
        {`UPDATE: We’ve missed you all and hope that everyone has been staying safe and healthy! We will be open for business starting on Monday, April 20th at 11:00am. Our hours going forward will be • Monday – Saturday 11am – 9pm • Sunday 12pm – 9pm • We will be offering curbside pick up and contact-free delivery! Lunch specials, pizza kits, pizza dough, and Sunday Dinner will also be available again.`}
      </p>
    </div>
  )
}
