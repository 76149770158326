import React from 'react'
import FoodMenu from './detail/FoodMenu.jsx'
import CateringMenu from './detail/CateringMenu.jsx'
import AboutUs from './detail/AboutUs.jsx'
import Social from './detail/Social.jsx'
import Reviews from './detail/Reviews.jsx'
import Gallery from './detail/Gallery.jsx'
import Newsletter from './detail/Newsletter.jsx'
import Order from './detail/Order.jsx'
import NotFound from './detail/NotFound.jsx'
import {
  Route,
  Switch
} from 'react-router-dom'


export default class Detail extends React.Component {

  constructor(props) {
    super(props)
    this.itemRef = React.createRef()
  }

  detailComponents = {
    "menu": <Route exact path='/menu' component={FoodMenu}></Route>,
    "catering": <Route exact path='/catering' component={CateringMenu}></Route>,
    "about": <Route exact path='/about' component={AboutUs}></Route>,
    "social": <Route exact path='/social' component={Social}></Route>,
    "reviews": <Route exact path='/reviews' component={Reviews}></Route>,
    "gallery": <Route exact path='/gallery' component={Gallery}></Route>,
    "newsletter": <Route exact path='/newsletter' component={Newsletter}></Route>,
    "order": <Route exact path='/order' component={Order}></Route>
}

  renderSection = () => {
    return ( this.detailComponents[this.props.name] )
  }

  render() {
    return(
      <div className='detail' ref={this.itemRef}>
        <Switch>
        {this.renderSection()}
        <Route exact path=''></Route>
        <Route component={NotFound}></Route>
        </Switch>
      </div>
    )
  }

  componentDidMount() {
    this.itemRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  componentDidUpdate() {
    this.itemRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

}
