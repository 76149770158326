import React, { useState } from 'react'
import FoodItemCard from './FoodItemCard.jsx'

export default function FoodCategoryCard(props) {

  const [showing, setShowing] = useState(false)

  const renderItems = () => {
    if (!showing) return
    return props.items.map((menuItem) => {
      return (
        <FoodItemCard
          key={menuItem.id}
          name={menuItem.name}
          desc={menuItem.desc}
          price={menuItem.price}>
        </FoodItemCard>
      )
    })
  }

  const toggleCategoryDisaply = () => {
    setShowing(!showing)
  }

  return (

    <div className="food-category-card">
      <span
        className="category-title"
        onClick={toggleCategoryDisaply}>
        {`- - - - ${props.category} - - - -`}
      </span>
      { showing &&
        props.subheading &&
        <div>{props.subheading}</div>
      }
      <section
        className='category-items'>
        {renderItems()}
      </section>
    </div>
  )
}
