import React from 'react'

export default function Order() {

  const grubhub = 'https://www.grubhub.com/restaurant/calabria-pizza-239-kinderkamack-rd-oradell/308818'
  const ezcater = 'https://www.ezcater.com/catering/pvt/calabria-pizza-oradell-3?fcv=1'
  const makeItButter = 'https://www.makeitbutter.com/calabria-pizza/calabria-pizza'

  const handleRegularOrder = () => window.open(makeItButter, 'tab')
  
  const handleCateringOrder = () => window.open(ezcater, 'tab')
  
  return (
    <div className="order">
      <section className="order-button-group">
        <p>{`Menu`}</p>
        <input
          className="order-button"
          type="button"
          value="Order"
          onClick={handleRegularOrder}>
        </input>
      </section>
      <section className="order-button-group">
        <p>{`Catering`}</p>
        <input
          className="order-button"
          type="button"
          value="Order"
          onClick={handleCateringOrder}>
        </input>
      </section>
    </div>
  )

}
