import React from 'react'
import CateringCategoryCard from './CateringCategoryCard.jsx'
import ToTopButton from '../../components/ToTopButton.jsx'
let cateringMenu = require('../../data/cateringMenu.json')

export default function CateringMenu(props) {

  const handleTopClick = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const renderCategories = () => {
    const menuCategoryList = []
    for (let menuCategory in cateringMenu) {
      menuCategoryList.push(
        <div key={menuCategory} className="food-category">
        <CateringCategoryCard
          category={menuCategory}
          items={cateringMenu[menuCategory]}>
        </CateringCategoryCard>
        </div>
      )
    }
    return menuCategoryList
  }

  return (
    <div className='catering-menu'>
      <>{renderCategories()}</>
    <ToTopButton handleTopClick={handleTopClick}></ToTopButton>
    </div>
  )
}
